<template>
  <div id="home">
    <div class="section-1">
      <div class="section-1__left">
        <div class="section-1__left-top">
          <div>
            <p class="section-1__title">
              布•可思益
            </p>
            <p class="section-1__subtitle-title">在全球愈發重視環保的當下，</p>
            <p class="section-1__subtitle-title">
              每一碼布料都承載著資源和心血。
            </p>
          </div>
        </div>
        <div class="section-1__left-bottom">
          <div>
            <p>賦予剩餘布料新生命，</p>
            <p>同時滿足少量布料需求。</p>
          </div>
        </div>
      </div>
      <div class="section-1__right">
        <img
          class="section-1__bg-img"
          :src="require('@/assets/home/section-1-bg.webp')"
          alt=""
        />
        <div class="section-1__right-bottom">
          <div>
            <p>我們承諾將不斷創新，讓您在享受高品質服務的同時，</p>
            <p>共同參與到保護環境的行動。</p>
          </div>
        </div>
      </div>
    </div>

    <div class="section-2">
      <h2 class="section-2__title">3R 永續</h2>
      <div class="section-2__images">
        <img
          :src="require('@/assets/home/section-2-reduce.webp')"
          class="section-2__img"
          alt=""
        />
        <img
          :src="require('@/assets/home/section-2-recycle.webp')"
          class="section-2__img"
          alt=""
        />
        <img
          :src="require('@/assets/home/section-2-reuse.webp')"
          class="section-2__img"
          alt=""
        />
      </div>
    </div>

    <div class="section-3">
      <div class="section-3__item">
        <div class="section-3__top">
          <img
            :src="require('@/assets/home/section-3-list.svg')"
            class="section-3__img"
            alt=""
          />
          <h4 class="section-3__title">MOQ小</h4>
        </div>
        <p class="section-3__description">
          打破傳統紡織業大量訂購的限制，無論您需要多小的數量都能被滿足。
        </p>
      </div>
      <div class="section-3__item">
        <div class="section-3__top">
          <img
            :src="require('@/assets/home/section-3-search.svg')"
            class="section-3__img w-100"
            alt=""
          />
          <h4 class="section-3__title">以圖找布</h4>
        </div>
        <p class="section-3__description">
          只需上傳圖片，平台將識別組織與相似外觀，讓您輕鬆找到所需布料。
        </p>
      </div>
      <div class="section-3__item">
        <div class="section-3__top">
          <img
            :src="require('@/assets/home/section-3-filter.svg')"
            class="section-3__img"
            alt=""
          />
          <h4 class="section-3__title">豐富的Filters</h4>
        </div>
        <p class="section-3__description">
          多元的 Filters，讓您的搜尋更高效精確，快速找到最適合的布料選擇。
        </p>
      </div>
      <div class="section-3__item">
        <div class="section-3__top">
          <img
            :src="require('@/assets/home/section-3-ai.svg')"
            class="section-3__img"
            alt=""
          />
          <h4 class="section-3__title">生成式 AI 設計</h4>
        </div>
        <p class="section-3__description">
          節省思辨創意的時間，同時激發創意靈感，開拓更多設計可能性。
        </p>
      </div>
    </div>

    <div class="section-4">
      <div class="section-4__left-container">
        <h3 class="section-4__title">環境影響指數揭露</h3>
        <h4 class="section-4__subtitle">Higg MSI 揭露布料對環境的影響，協助您選用低碳足跡布料。</h4>
      </div>
      <img
        :src="require('@/assets/home/section-4-img.webp')"
        alt=""
        class="section-4__img"
      />
    </div>
    <div class="section-5">
      <div class="section-5__box">
        <img
          :src="require('@/assets/home/section-5-img.png')"
          alt=""
          class="section-5__img"
        />
        <div class="section-5__right-container">
          <h3 class="section-5__title">數位 3D 布料展示</h3>
          <h4 class="section-5__subtitle">使用 NUNO-X 科技解決方案，減少視覺上的差異感。</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="stylus">
#home
  flex(flex-start, center, column)

.section-1
  flex()
  height calc(100vh - 60px)
  width 100%
  overflow-x hidden

.section-1__left
  flex(space-between, center, column)
  width 40%
  height 100%

.section-1__left-top
  flex()
  width 100%
  height calc(100% - 300px)
  background-color white
  > div
    width 80%

.section-1__title
  margin-left 20px
  color $color-warning
  font-size 60px
  font-weight bold
  cursor pointer
  &:hover
    transform scale(1.2)


.section-1__subtitle-title
  margin-left 100px
  font-size 20px

.section-1__left-bottom
  flex()
  width 100%
  height 300px
  background-color #4A9A8A
  > div
    width 65%
  p
    color white
    font-size 24px

.section-1__right
  flex(flex-end, center, column)
  width 60%
  height 100%

.section-1__bg-img
  width 100%
  height 100%
  object-fit cover
  object-position center

.section-1__right-bottom
  flex()
  position absolute
  right 0
  bottom 0
  height 300px
  width 60%
  > div
    width 70%
    z-index 9
  p
    font-size 24px
.section-1__right-bottom:after
  content ''
  position absolute
  top 0
  right 0
  bottom 0
  left 0
  background white
  opacity .6

.section-2
  flex(flex-start, center, column)

.section-2__title
  color $color-warning
  font-size 50px
  font-weight bold
  margin-top 60px

.section-2__images
  flex(space-between)
  width 100%
  max-width 1300px
  margin 100px 0

.section-2__img
  height 300px
  margin 0 20px
  width auto
  object-fit contain
  object-position center

.section-3
  flex()
  background $color-background-primary
  width 100%
  padding 100px 50px

.section-3__item
  width 200px
  margin 0 50px
  flex(flex-start, center, column)

.section-3__top
  flex(center, center ,column)
  height 140px

.section-3__img
  width 80px
  height auto

.section-3__title
  margin 10px 0
  color white
  font-weight bold
  font-size $font-size-lg4x

.section-3__description
  margin 10px 0
  color white
  font-size $font-size-lg2x

.section-4
  flex(space-between)
  padding 100px 50px

.section-4__left-container
  margin-right 50px
  width 400px

.section-4__title
  font-size $font-size-title
  font-weight bold
  color $color-warning

.section-4__subtitle
  font-size $font-size-zoom-title

.section-4__img
  width 800px
  height auto

.section-5
  flex()
  width 100%
  background #D1E7E0

.section-5__box
  width 1200px
  padding 100px 50px
  flex(space-between)
.section-5__right-container
  margin-left 50px
  width 400px

.section-5__title
  font-size $font-size-title
  font-weight bold
  color $color-warning

.section-5__subtitle
  font-size $font-size-zoom-title

.section-5__img
  width 800px
  height auto

.w-100
  width 100px !important
</style>
